import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Control, ControlInheritance } from '../core/models/Control';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ControlsService {
  public controls: Control[];
  public control: Control;
  public dueControls = new BehaviorSubject<any>(0);
  public dueControls$ = this.dueControls.asObservable();
  metaDataSource: BehaviorSubject<{ parentControl: Control, controlInheritance: ControlInheritance }> =
    new BehaviorSubject({ parentControl: null, controlInheritance: null });
  inheritanceMetaData = this.metaDataSource.asObservable();
  constructor(private http: HttpClient) { }

  index(currentPage = 0, pageSize = 20, { program = null, filter = null } = {}) {
    let params = new HttpParams()
      .set('page', currentPage.toString())
      .set('per', pageSize.toString());

    if (program) {
      params = params.append('program', program);
    }

    if (filter) {
      params = params.append('filter', filter);
    }

    return this.http
      .get<any>(`${environment.apiUrl}/controls`, { params })
      .pipe(map(controls => controls));
  }

  getAll(programId: string, params?: any) {
    return this.http
      .get<any>(`${environment.apiUrl}/programs/${programId}/controls`, { params })
      .pipe(map(controls => controls));
  }

  getControl(controlId: string) {
    return this.http
      .get<Control>(`${environment.apiUrl}/controls/${controlId}`)
      .pipe(map(control => control));
  }

  getControlActivity(controlId: string, filter?: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/controls/${controlId}/activity`, {
        ...filter && { params: new HttpParams().set('filter', filter) }
      })
      .pipe(map(control => control));
  }

  addControl(programId: string, data: FormData) {
    return this.http
      .post<any>(`${environment.apiUrl}/programs/${programId}/controls`, data)
      .pipe(map(record => record));
  }

  updateControl(programId: string, controlId: string, data: FormData) {
    return this.http
      .put<any>(`${environment.apiUrl}/programs/${programId}/controls/${controlId}`, data)
      .pipe(map(record => record));
  }

  bulkUpdateControls(data) {
    return this.http
      .put<any>(`${environment.apiUrl}/controls/bulkUpdate`, { data })
      .pipe(map(controls => controls));
  }
  completeReview(controlId: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/controls/${controlId}/complete-review`, {})
      .pipe(map(record => record));
  }

  deleteControl(programId: string, controlId: string) {
    return this.http
      .delete<any>(`${environment.apiUrl}/programs/${programId}/controls/${controlId}`)
      .pipe(map(result => result));
  }

  deleteEvidence(controlId: string, fileId: string) {
    return this.http
      .delete<any>(`${environment.apiUrl}/controls/${controlId}/attachment/${fileId}`)
      .pipe(map(result => result));
  }

  downloadAttachment(id: string, fileId: string) {
    return this.http
      .post(`${environment.apiUrl}/controls/${id}/attachment/${fileId}`, {}, {
        responseType: 'blob',
      })
      .pipe(map(result => result));
  }

  duplicate(controlId: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/controls/${controlId}/duplicate`, {})
      .pipe(map(record => record));
  }

  toggleNotifications(controlId: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/controls/${controlId}/notifications`, {})
      .pipe(map(record => record));
  }

  viewIncidents(controlId: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/controls/${controlId}/incidents`, {})
      .pipe(map(record => record));
  }
  getDueControls(): Observable<number> {
    return this.dueControls$;
  }
  setDueControls(latestCount) {
    this.dueControls.next(latestCount);
  }
  inheritControl(parentControl, childControl): Observable<any> {
    return this.http.post(`${environment.apiUrl}/controls/inheritcontrol`, {},
      { params: { parentControl, childControl } }).pipe(record => record);
  }
  destroyInheritance(controlid) {
    return this.http.delete(`${environment.apiUrl}/controls/destroyinheritance`, { params: { controlid } }).pipe(record => record);
  }
  listControlInheritances(programId) {
    return this.http.post(`${environment.apiUrl}/controls/listControlInheritances`, {}, { params: { programId } })
      .pipe(map(result => result));
  }
  listChildControls(controlId) {
    return this.http.get<any>(`${environment.apiUrl}/controls/listChildControls/${controlId}`).pipe(record => record);
  }
  emitInheritanceMetaData(data) {
    this.metaDataSource.next(data);
  }
}
